// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).__Zone_enable_cross_context_check = true;

import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';
import 'core-js/es/reflect';
import 'zone.js';
import 'element-closest';
import '@angular/localize/init';
